import * as bootstrap from 'bootstrap';

const openPopupBtn = document.querySelectorAll('.openPopupBtn');

openPopupBtn.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    e.target.style.display = 'block';
    console.log('clicked');
  });
});

